import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Title from '../components/title'
import DesktopImage from '../images/native-ad-desktop.png'
import MobileImage from '../images/native-ad-mobile.png'


const NativeExample = () => (
  <Layout>
    <SEO title="Native Example" />
    <div className="native-example">
      <Title 
        title="Native Example"
        align="center"
      />
      <p>(Scroll down)</p>
      <div className="native-example__image native-example__image--desktop">
        <img src={DesktopImage} alt="Native Example desktop" />
      </div>

      <div className="native-example__image native-example__image--mobile">
        <img src={MobileImage} alt="Native Example mobile" />
      </div>
    </div>
  </Layout>
)

export default NativeExample
