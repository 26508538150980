import React from "react";

const Title = ({title, type, align = false, weight = false, color = false, underline = false, innerPageTitle = false}) => {

    const createClassList = () => {
        let classList = 'c-title';
        if(align) classList = classList + ' c-title--' + align;
        if(weight) classList = classList + ' c-title--w-' + weight;
        if(color) classList = classList + ' c-title--' + color;
        if(underline) classList = classList + ' c-title--underline';
        if(innerPageTitle) classList = classList + ' c-title--inner-page';
        return classList;
    }

    const underlineElement = (underline) ? (
        <div className='c-title__underline'></div>
    ) : null;

    const titleElement = () => {
        switch(type) {
            case 'h1':
                return (
                    <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
                )
            case 'h2':
                return (
                    <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                )
            case 'h3':
                return (
                    <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
                )
            case 'h4':
                return (
                    <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                )
            case 'h5':
                return (
                    <h5 dangerouslySetInnerHTML={{ __html: title }}></h5>
                )
            default:
                return (
                    <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                )
        }
    }
    
    return (
        <div className={createClassList()}>
            {titleElement()}
            {underlineElement}
        </div>
    );
}

export default Title;